import { makeCallSpy } from 'utils/rxjs';
import { parseJson } from 'api/utils/RestSDK';

import { withAutoRefresh } from '../auth';
import { BASE_URL, getPrivateHeaders } from '../constants';
import { JsonResponses, RequestBody, schemas } from '../types';

type URL = '/api/v1/subscriptions/create';

export type SubscribeMarketResponse = schemas['SubscriptionResponse'];

export type MarketSubscriptionEntity = {
  productSlug: string;
  versionSlug: string;
  networkSlug: string;
  marketVid: string;
};

export const [subscribeMarket, { executedCalls$: subscribeMarketExecuted$ }] = makeCallSpy(
  withAutoRefresh(
    async ({
      marketVid,
      networkSlug,
      productSlug,
      versionSlug,
    }: MarketSubscriptionEntity): Promise<SubscribeMarketResponse> => {
      const body: RequestBody<URL> = {
        subscription_entity: {
          entity_type: 'MARKET',
          product_slug: productSlug,
          version_slug: versionSlug,
          network_slug: networkSlug,
          market_vid: marketVid,
        },
        receiver_entity: {
          delivery_method: 'EMAIL',
        },
        subscription_type: 'MARKET_TOTAL_RATING_CHANGE',
      };

      const response = await fetch(`${BASE_URL}/v1/subscriptions/create`, {
        headers: getPrivateHeaders(),
        method: 'POST',
        body: JSON.stringify(body),
      });

      return parseJson<JsonResponses<URL, 'post'>>(response);
    },
  ),
);
