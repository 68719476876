import React from 'react';

import {
  loadMarketSubscriptions,
  subscribeMarket,
  unsubscribeMarket,
  MarketSubscriptionEntity,
} from 'api/modules/apostro-rest/private';
import { useRouteUtils } from 'routes';

import { SubscribeButton } from './components';

export function MarketSubscribeButton(props: MarketSubscriptionEntity) {
  const { isCustomHost } = useRouteUtils();

  if (isCustomHost) {
    return null;
  }

  return (
    <SubscribeButton
      payload={props}
      loadSubscriptions={loadMarketSubscriptions}
      subscribe={subscribeMarket}
      unsubscribe={unsubscribeMarket}
    />
  );
}
