import { merge } from 'rxjs';

import { Handler } from 'api/utils/RestSDK';
import { LONG_POLLING_TIMEOUT } from 'env';
import { timer } from 'utils/rxjs';

import { JsonResponses, RequestBody } from '../types';
import { BASE_URL, getPrivateHeaders } from '../constants';
import { withAutoRefresh } from '../auth';
import { MarketSubscriptionEntity, subscribeMarketExecuted$ } from './subscribeMarket';
import { unsubscribeMarketExecuted$ } from './unsubscribeMarket';

type URL = '/api/v1/subscriptions/filter';

export const loadMarketSubscriptions = withAutoRefresh(
  Handler.new<MarketSubscriptionEntity | void>()
    .fetch(
      () => `${BASE_URL}/v1/subscriptions/filter`,
      params => {
        const body: RequestBody<URL> = {
          subscription_entity: params
            ? {
                entity_type: 'MARKET',
                product_slug: params.productSlug,
                version_slug: params.versionSlug,
                network_slug: params.networkSlug,
                market_vid: params.marketVid,
              }
            : undefined,
          receiver_entity: {
            delivery_method: 'EMAIL',
          },
          subscription_type: 'MARKET_TOTAL_RATING_CHANGE',
        };

        return {
          headers: getPrivateHeaders(),
          method: 'POST',
          body: JSON.stringify(body),
        };
      },
    )
    .parseJson<JsonResponses<URL, 'post'>>()
    .refetchByStream(
      merge(timer(0, LONG_POLLING_TIMEOUT), subscribeMarketExecuted$, unsubscribeMarketExecuted$),
    )
    .toFn(),
);
