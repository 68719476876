import { merge } from 'rxjs';

import { Handler } from 'api/utils/RestSDK';
import { timer } from 'utils/rxjs';
import { LONG_POLLING_TIMEOUT } from 'env';

import { JsonResponses, RequestBody } from '../types';
import { BASE_URL, getPrivateHeaders } from '../constants';
import { withAutoRefresh } from '../auth';
import { subscribeAccountExecuted$ } from './subscribeAccount';
import { unsubscribeAccountExecuted$ } from './unsubscribeAccount';

type URL = '/api/v1/subscriptions/filter';

export const loadAccountSubscriptions = withAutoRefresh(
  Handler.new<{ address: string } | void>()
    .fetch(
      () => `${BASE_URL}/v1/subscriptions/filter`,
      params => {
        const body: RequestBody<URL> = {
          subscription_entity: params
            ? {
                entity_type: 'NETWORK_ACCOUNT',
                network_account_vid: params.address.toLowerCase(),
              }
            : undefined,
          receiver_entity: {
            delivery_method: 'EMAIL',
          },
          subscription_type: 'NETWORK_ACCOUNT_WEIGHTED_AVERAGE_RATING_CHANGE',
        };

        return {
          headers: getPrivateHeaders(),
          method: 'POST',
          body: JSON.stringify(body),
        };
      },
    )
    .parseJson<JsonResponses<URL, 'post'>>()
    .refetchByStream(
      merge(timer(0, LONG_POLLING_TIMEOUT), subscribeAccountExecuted$, unsubscribeAccountExecuted$),
    )
    .toFn(),
);
