import { makeCallSpy } from 'utils/rxjs';
import { parseJson } from 'api/utils/RestSDK';

import { withAutoRefresh } from '../auth';
import { BASE_URL, getPrivateHeaders } from '../constants';
import { JsonResponses, RequestBody, schemas } from '../types';

type URL = '/api/v1/subscriptions/create';

export type SubscribeAccountResponse = schemas['SubscriptionResponse'];

export const [subscribeAccount, { executedCalls$: subscribeAccountExecuted$ }] = makeCallSpy(
  withAutoRefresh(async ({ address }: { address: string }): Promise<SubscribeAccountResponse> => {
    const body: RequestBody<URL> = {
      subscription_entity: {
        entity_type: 'NETWORK_ACCOUNT',
        network_account_vid: address.toLowerCase(),
      },
      receiver_entity: {
        delivery_method: 'EMAIL',
      },
      subscription_type: 'NETWORK_ACCOUNT_WEIGHTED_AVERAGE_RATING_CHANGE',
    };

    const response = await fetch(`${BASE_URL}/v1/subscriptions/create`, {
      headers: getPrivateHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    });

    return parseJson<JsonResponses<URL, 'post'>>(response);
  }),
);
