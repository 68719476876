import React from 'react';
import { ButtonBase, CSSInterpolation, CircularProgress } from '@mui/material';

import { colors, styled } from 'core/styles';
import { NotificationIcon } from 'components/icons';

type SubscriptionButtonVariant = 'light' | 'dark';

type SubscriptionButtonProps = {
  title: string;
  variant: SubscriptionButtonVariant;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
};

export function SubscribeButtonBase({
  title,
  variant,
  disabled,
  onClick,
  isLoading,
}: SubscriptionButtonProps) {
  return (
    <Root variant={variant} disabled={disabled} onClick={onClick}>
      {isLoading ? (
        <CircularProgress size={24} sx={{ padding: '2px' }} color="inherit" />
      ) : (
        <IconContainer>
          <NotificationIcon fontSize="inherit" />
        </IconContainer>
      )}

      {title}
    </Root>
  );
}

const Root = styled(ButtonBase, {
  shouldForwardProp: prop => !['variant'].includes(String(prop)),
})<{
  variant: SubscriptionButtonVariant;
}>(({ variant }) => ({
  height: 32,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,
  fontSize: 14,
  padding: '4px 12px 4px 6px',
  borderRadius: '100px',
  whiteSpace: 'nowrap',
  transition: 'background 200ms',
  ...getRootStylesByVariant({ variant }),

  '&:focus': {
    outline: 'none',
  },
}));

const IconContainer = styled('span')({
  display: 'inline-flex',
  fontSize: 24,
  color: 'inherit',
});

function getRootStylesByVariant({
  variant,
}: {
  variant: SubscriptionButtonVariant;
}): Record<string, CSSInterpolation> {
  switch (variant) {
    case 'light':
      return {
        background: colors.springGreen[12],
        color: colors.black,

        '&:hover': {
          background: colors.springGreen[36],
        },
      };
    case 'dark':
      return {
        background: colors.silver,
        color: colors.white,
        transition: 'opacity 200ms',

        '&:hover': {
          opacity: 0.5,
        },
      };
  }
}
