import { Tooltip } from '@mui/material';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { Observable, of } from 'rxjs';
import * as R from 'ramda';

import { Authorized, Unauthorized, useAuthorized } from 'services/auth';
import { useSubscribable, useOptimistic } from 'utils/react';
import { Loading } from 'components';
import { success } from 'utils/remoteData';
import { authApi } from 'api/modules';

import { SubscribeButtonBase } from './SubscribeButtonBase';

type Props<P> = {
  payload: P;
  loadSubscriptions: (payload: P) => Observable<{ subscription_id: number }[]>;
  subscribe: (payload: P) => Promise<{ subscription_id: number }>;
  unsubscribe: (subscription_id: number) => Promise<{ subscription_id: number }>;
};

type OptimisticAction =
  | {
      type: 'subscribed';
      subscriptionId: number;
    }
  | {
      type: 'unsubscribed';
    };

export function SubscribeButton<P>(props: Props<P>) {
  const authorized = useAuthorized();
  const { payload, loadSubscriptions, subscribe, unsubscribe } = props;
  const rd = useSubscribable(
    () => (authorized ? loadSubscriptions(payload) : of([])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [R.toString(payload), authorized],
    {
      clientOnly: true,
    },
  );
  const subscriptionIdRD = rd.map(subscriptions =>
    subscriptions.length > 0 ? subscriptions[0].subscription_id : null,
  );

  const [optimisticRD, applyAction, isOptimisticActive] = useOptimistic(
    subscriptionIdRD,
    (_, action: OptimisticAction) => {
      switch (action.type) {
        case 'subscribed':
          return success(action.subscriptionId);
        case 'unsubscribed':
          return success(null);
      }
    },
  );

  const [subscribing, handleSubscribeClick] = useAsyncFn(async (_payload: P) => {
    const response = await subscribe(_payload);
    applyAction({ type: 'subscribed', subscriptionId: response.subscription_id });
  });
  const [unsubscribing, handleUnsubscribeClick] = useAsyncFn(async (subscriptionId: number) => {
    await unsubscribe(subscriptionId);
    applyAction({ type: 'unsubscribed' });
  });

  const loading = subscribing.loading || unsubscribing.loading;

  return (
    <>
      <Authorized>
        <Loading data={optimisticRD} loader={null}>
          {subscriptionId =>
            subscriptionId !== null ? (
              <SubscribeButtonBase
                title="Unsubscribe"
                variant="dark"
                onClick={() => handleUnsubscribeClick(subscriptionId)}
                isLoading={loading}
                disabled={loading || isOptimisticActive}
              />
            ) : (
              <SubscribeButtonBase
                title="Subscribe"
                variant="light"
                onClick={() => handleSubscribeClick(payload)}
                isLoading={loading}
                disabled={loading || isOptimisticActive}
              />
            )
          }
        </Loading>
      </Authorized>
      <Unauthorized>
        <Tooltip title="Subscription is available only to authorized users">
          <div>
            <SubscribeButtonBase title="Subscribe" variant="dark" onClick={authApi.openModal} />
          </div>
        </Tooltip>
      </Unauthorized>
    </>
  );
}
