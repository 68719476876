import React from 'react';

import {
  loadAccountSubscriptions,
  subscribeAccount,
  unsubscribeAccount,
} from 'api/modules/apostro-rest/private';
import { useRouteUtils } from 'routes';

import { SubscribeButton } from './components';

type Props = {
  address: string;
};

export function AccountSubscribeButton({ address }: Props) {
  const { isCustomHost } = useRouteUtils();

  if (isCustomHost) {
    return null;
  }

  return (
    <SubscribeButton
      payload={{ address }}
      loadSubscriptions={loadAccountSubscriptions}
      subscribe={subscribeAccount}
      unsubscribe={unsubscribeAccount}
    />
  );
}
