import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// tslint:disable:max-line-length
function NotificationIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g id="icon" fill="none">
        <path
          id="Rectangle 126"
          d="M7 12C7 9.23858 9.23858 7 12 7V7C14.7614 7 17 9.23858 17 12V17H7V12Z"
          stroke="currentColor"
          strokeWidth="1.25"
        />
        <circle id="Ellipse 29" cx="12" cy="4" r="1" fill="currentColor" />
        <line
          id="Line 603"
          x1="10"
          y1="19.375"
          x2="14"
          y2="19.375"
          stroke="currentColor"
          strokeWidth="1.25"
        />
      </g>
    </SvgIcon>
  );
}

export { NotificationIcon };
