import React from 'react';
import {
  styled,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  FormControl,
} from '@mui/material';

import { colors } from 'core/styles';
import { NetworkIcon, NextLink } from 'components';
import { ArrowDownIcon } from 'components/icons';
import { NetworkSlug, VersionSlug } from 'domain/types';

import CheckMarkIcon from './images/CheckMarkIcon.svg';
import { Network } from './types';

export type NetworkSwitcherProps = {
  networks: Network[];
  currentNetwork: NetworkSlug;
  currentVersion: VersionSlug;
};

export function NetworkSwitcher({
  networks,
  currentNetwork,
  currentVersion,
}: NetworkSwitcherProps) {
  if (!networks.length) {
    return null;
  }

  if (networks.length === 1) {
    return (
      <NetworkLabelWrapper>
        <NetworkLabel
          network={currentNetwork}
          networkName={networks[0].networkName}
          versionName=""
        />
      </NetworkLabelWrapper>
    );
  }

  return (
    <FormControl>
      <Select
        value={currentNetwork + currentVersion}
        size="small"
        label="network switcher"
        IconComponent={SelectIcon}
        MenuProps={menuSX}
      >
        {networks.map(({ network, versionName, versionSlug, networkName, href }) => (
          <MenuItem key={network + versionSlug} value={network + versionSlug}>
            <NextLink href={href}>
              <NetworkLabel network={network} networkName={networkName} versionName={versionName} />
            </NextLink>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function NetworkLabel({
  network,
  networkName,
  versionName,
}: {
  network: NetworkSlug;
  networkName: string;
  versionName: string;
}): JSX.Element {
  return (
    <NetworkLabelBox>
      <ListItemIcon>
        <NetworkIcon slug={network} fontSize="inherit" />
      </ListItemIcon>
      <ListItemText>
        {versionName ? `${versionName} · ` : ''}
        {networkName}
      </ListItemText>
    </NetworkLabelBox>
  );
}

const menuSX = {
  sx: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiMenu-paper': {
      backgroundColor: colors.smokyWhite,
      backgroundImage: 'none',
      borderRadius: '4px',
      marginTop: '4px',

      '& .MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '4px',
      },
    },
  },
};

const SelectIcon = styled(ArrowDownIcon)(() => ({
  fontSize: '20px',
  '&.MuiSvgIcon-root.MuiSelect-icon': {
    right: 4,
  },
}));

const Select = styled(MuiSelect)(() => ({
  backgroundColor: 'transparent',
  height: 32,

  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& [aria-expanded="true"]': {
    backgroundColor: colors.smokyWhite,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    padding: '0 9px 0 10px',
  },

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
  },

  '& .MuiSelect-select.MuiSelect-outlined': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background 300ms',

    '&:hover': {
      backgroundColor: colors.smokyWhite,
    },

    '&.MuiInputBase-input': {
      padding: '4px 28px 4px 4px',
    },
  },
}));

const NetworkLabelWrapper = styled('div')({
  height: 32,
  display: 'flex',
});

const NetworkLabelBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 10px',

  '.MuiMenuItem-root &': {
    padding: '4px 50px 4px 4px',
  },

  '.MuiSelect-select &': {
    padding: 0,
  },
});

const MenuItem = styled(MuiMenuItem)(() => ({
  position: 'relative',
  display: 'block',
  padding: 0,

  '&:not(:last-of-type)': {
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '1px',
      bottom: '-4px',
      background: colors.silver,
    },
  },

  '&.MuiMenuItem-root': {
    transition: 'background 300ms',
    minHeight: 'auto',

    '&:hover': {
      backgroundColor: colors.blackOpacity[5],
      borderRadius: '4px',
    },
    '&.Mui-selected': {
      position: 'relative',
      backgroundColor: 'transparent',

      '&::after': {
        position: 'absolute',
        content: `url(${CheckMarkIcon.src})`,
        right: 4,
        top: 6,
        width: 20,
        height: 20,
        background: 'transparent',
      },
    },
    '&.Mui-selected:hover ': {
      backgroundColor: colors.whiteOpacity[5],
    },
  },

  '& .MuiListItemIcon-root': listItemIconStyles,
}));

const ListItemIcon = styled(MuiListItemIcon)(() => ({
  '&.MuiListItemIcon-root': listItemIconStyles,
}));

const listItemIconStyles = {
  minWidth: '16px',
  marginRight: '8px',
  fontSize: 24,
};

const ListItemText = styled(MuiListItemText)(() => ({
  color: colors.black,
  margin: 0,

  '& .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: 1,
  },
}));
