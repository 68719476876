import { makeCallSpy } from 'utils/rxjs';
import { parseJson } from 'api/utils/RestSDK';

import { withAutoRefresh } from '../auth';
import { BASE_URL, getPrivateHeaders } from '../constants';
import { JsonResponses, RequestBody, schemas } from '../types';

type URL = '/api/v1/subscriptions/delete';

export type UnsubscribeAccountResponse = schemas['SubscriptionResponse'];

export const [unsubscribeAccount, { executedCalls$: unsubscribeAccountExecuted$ }] = makeCallSpy(
  withAutoRefresh(async (subscriptionId: number): Promise<UnsubscribeAccountResponse> => {
    const body: RequestBody<URL> = {
      subscription_id: subscriptionId,
    };

    const response = await fetch(`${BASE_URL}/v1/subscriptions/delete`, {
      headers: getPrivateHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    });

    return parseJson<JsonResponses<URL, 'post'>>(response);
  }),
);
