import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// tslint:disable:max-line-length
function ArrowDownIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
      <path d="M15 9L10 13L5 9" stroke="black" fill="none" />
    </SvgIcon>
  );
}

export { ArrowDownIcon };
