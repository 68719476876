import React from 'react';

import { colors, styled } from 'core/styles';
import { NavigationLink } from 'components';

import { NetworkSwitcher, NetworkSwitcherProps } from './NetworkSwitcher';

export type NavbarLink = {
  href: string;
  label: string;
};

type NavbarProps = {
  links: NavbarLink[];
  networkSwitcherProps: NetworkSwitcherProps | null;
};

export function Navbar({ links, networkSwitcherProps }: NavbarProps) {
  return (
    <Root>
      <ScrollShadow>
        <NavLinks>
          {links.map((link, index) => (
            <NavigationLink key={index} label={link.label} href={link.href} type="tab" />
          ))}
        </NavLinks>
      </ScrollShadow>
      {networkSwitcherProps && (
        <NetworksBox>
          <NetworkSwitcher {...networkSwitcherProps} />
        </NetworksBox>
      )}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 14,
  gap: 16,
});

const ScrollShadow = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',

  '&:after': {
    position: 'absolute',
    top: 0,
    right: 0,
    content: "''",
    width: 24,
    height: '100%',
    background: `linear-gradient(270deg, ${colors.white} 0%, transparent 100%)`,
    flexShrink: 0,
  },

  [theme.breakpoints.up('mobileMD')]: {
    maxWidth: '100%',
    overflow: 'auto',
    '&::after': {
      display: 'none',
    },
  },
}));

const NavLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 16,
  overflow: 'auto',
  paddingRight: 24,

  [theme.breakpoints.up('mobileMD')]: {
    paddingRight: 0,
  },
}));

const NetworksBox = styled('div')({
  flexShrink: 0,
});
