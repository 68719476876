import React from 'react';
import { useScroll, useSpring, animated } from '@react-spring/web';

import { styled } from 'core/styles';

type TitleProps = {
  icon: React.ReactNode | null;
  title: React.ReactNode;
};

export function FloatingTitle({ icon, title }: TitleProps) {
  const [titleStyles, titleApi] = useSpring(() => ({
    opacity: 0,
    config: {
      duration: 200,
    },
  }));

  useScroll({
    onChange: ({ value: { scrollY } }) => {
      if (scrollY > 124) {
        titleApi.start({ opacity: 1 });
      } else {
        titleApi.start({ opacity: 0 });
      }
    },
  });

  return (
    <Root
      style={{
        ...titleStyles,
      }}
    >
      {icon}
      <Name>{title}</Name>
    </Root>
  );
}

const Root = styled(animated.div)(({ theme }) => ({
  position: 'absolute',
  bottom: 10,
  left: 16,
  alignItems: 'center',
  gap: 8,
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Name = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.secondaryFontFamily,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '-0.25px',
  display: 'none',
  maxWidth: 180,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  [theme.breakpoints.up('tabletLG')]: {
    display: 'inline',
  },
}));
